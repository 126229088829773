module.exports = global.config = {
        app: {
            name: "Live Cricket Kerala",
            short_name: "Live Cricket Kerala",
            website: "4cr.in",
            backend_api_url7:"http://localhost:8074/4cr_in/index.php/",
            backend_api_url9:"https://musheertm.4cr.in/api/index.php/",
            backend_api_url8:"https://testing.4cr.in/api/index.php/",
            backend_api_url:"https://4cr.in/api/index.php/",
            ga_code:"'G-1TVVV50CP6"            
        }
        // rest of your translation object
    // other global config variables you wish
};